import React from "react"
import Button from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Button"
import ImageReveal from "../reveals/ImageReveal"

const ButtonGroup = ({ className = "", buttons = [] }) => {
  return (
    <>
      {buttons.length > 0 && (
        <div className={`${className} -mb-4`}>
          {buttons.map((button, index) => (
            <Button
              key={index}
              className={`mb-4 ${index + 1 < buttons.length ? "mr-4" : ""}`}
              btnType={index > 0 ? "secondary" : "primary"}
              link={button.link}
              target={button.target}
              key={index}
              externalIcon={button.external_icon}
              icon={button.icon}
              onClick={button.onClick}
            >
              {button.link_text}
            </Button>
          ))}
        </div>
      )}
    </>
  )
}

export default ButtonGroup
